@import "../../vars.scss";

.addToCart{
	background: $white;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 45px;
	height: 45px;
	border: 2px solid $brand;
}

.addToCartFullProdForm{
	margin-top: 15px;
	// !! all inner inputs & selects 
	> * {
		margin-bottom: 15px !important;
	}
}