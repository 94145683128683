@import "vars.scss";

// !!
a,svg{
	cursor: pointer;
	&:hover{
		filter: contrast(80%);
		fill: $brand !important;
		color: $brand !important;
		transition: 0.3s;
	}
}

.category,.cartReminder,.FAQ{
	text-wrap: nowrap; // fixes category text-wrap on hover
	cursor: pointer;
	&:hover{
		filter: contrast(80%);
		transition: 0.2s;
		box-shadow: 0px 10px 15px -3px rgba(138, 138, 138, 0.15) !important;
	}
}

select{
	cursor: pointer;
	&:hover{
		filter: contrast(50%);
	}
	&:focus{
		filter: contrast(10%);
	}
}

.filter,.searchField{
	&:hover{
		filter: contrast(70%);
	}
}

// ! null a,svg hover (prev) on ButtonLink
.skipHover{
	&:hover{
		filter: contrast(100%);
		color: $white !important;
	}
}

.cartCard{
	// cartCard icons
	svg{
		cursor: pointer;
	}
}

.scrollableArea{
	cursor: grab;
	.card{
		cursor: grab !important;
	}
}

// !! cards
.card, .cartCard, .orderCard{
	box-shadow: $boxShadowLight;
	&:hover{
		box-shadow: $boxShadowHard;
		cursor: pointer;
	}
}

.subTotal,.shippingForm,.postFull{
	box-shadow: $boxShadowLight;
}

// cursors
.curPointer{
	cursor: pointer;
}
.curCopy{
	cursor: copy;
}
.curAlias{
	cursor: alias;
}

// other
.hfsi{
	&:hover{
		transition: 0.1s;
		font-style: italic !important;
	}
}

.uploadedImg{
	&:hover{
		border: 1px solid $brand !important;
		scale: 0.99 !important;
	}
}

// scaleDown 1
svg,.FAQ__toggle{
	&:hover{
		scale: 0.9 !important;
	}
	&:active{
		scale: 1 !important;
	}
}

// scaleDown 2
.cartCardImg,.cartReminder,.FAQ {
	&:hover{
		scale: 0.98 !important;
	}
	&:active{
		scale: 1 !important;
	}
}

// ! hover
.hoverBrand{
	transition: 0.3s;
	&:hover{
		color: $brand !important;
	}
}

.hoverScale{
	transition: 0.3s;
	&:hover{
		scale: 0.95 !important;
	}
}