@import "../../vars.scss";

// !!
footer{
	background: $dark700;
	color: $white;
	font-size: 20px; // TODO ???
	// !! all links margin
	* {
		margin-bottom: 10px;
	}
	padding-top: 40px;
	padding-bottom: 40px;
	// media 
	@media (max-width:$mobile) {
		padding-bottom: 100px;
	}
	// !!
	a {
		color: $white;
		text-decoration: none;
	}
}