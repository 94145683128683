@import "../../vars.scss";

// mui dialog
.MuiDialog-container.MuiDialog-scrollPaper{
	// dialog img
	img{
		max-height: 30vh;
		object-fit: cover;
	}
	// media pc
	@media (width > $mobile) {
		height: calc(80% - 60px); 
		// dialog width
		.MuiPaper-root{
			width: 675px !important;
		}
	}
	// media mobile
	@media (width <= $mobile) {
		height: calc(100% - 55px); 
		// dialog width
		.MuiPaper-root{
			width: 330px !important;
		}
	}
	// ! INSIDE DIALOG
	.cartCard{
		overflow-x: hidden !important;
		width: 100% !important; // !! prevent scrollX in dialog cause of too wide cartCard (with long title)
		// ! mobile
		@media (width <= $mobile) {
			&__title{
				width: calc($wS - 30px) !important;
			}
		}
		// ! pc
		@media (width > $mobile) {
			width: calc(100% - 30px) !important; // !! prevent cartCard small width in dialog
			&__title{
				// PC cartCard title inside dialog
				width: 340px; // add "..." to title: + needs "oh wsn toe" in Component Class
			}
		}
	}
}

// ! OUTSIDE DIALOG
.cartCard{
	@media (width > $mobile) {
		&__title{
			// PC cartCard title outside dialog (CART)
			width: 460px; // add "..." to title: + needs "oh wsn toe" in Component Class
		}
	}
}

.imgContentWrap{
	@media (width <= $mobile) {
		width: 100%;
		display: flex;
		flex-direction: column;
	}
	@media (width > $mobile) {
		width: 95%;
		display: flex;
		flex-wrap: nowrap;
	}
}

.contentWrap{
	@media (width <= $mobile) {
		width: 100%;
	}
	@media (width > $mobile) {
		padding-left: 15px;
		width: 95%;
	}
}

// text-overflow: ellipsis NOT working
// .contentWrap{
// 	white-space: nowrap !important;
// 	overflow: hidden !important;
// 	text-overflow: ellipsis !important;
// 	@media (width <= $mobile) {
// 		width: 200px !important;
// 	}
// 	@media (width > $mobile) {
// 		width: 500px !important;
// 		padding-left: 15px;
// 	}
// }