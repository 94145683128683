@import "./vars.scss";
@import url('https://fonts.googleapis.com/css2?family=Fuggles:wght@100;200;300;400;500;600;700&display=block');

// ! SHORTCUTS
// ! FLEX
.f{
	display: flex;
	flex-wrap: wrap;
}
.if{
	display: inline-flex;
	flex-wrap: wrap;
}
.fdr{
	flex-direction: row;
}
.fwn{
	flex-wrap: nowrap;
}
.fwni{
	flex-wrap: nowrap !important;
}
.fc{
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
}
.fcc{
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
}
.aic{
	align-items: center;
}
.aifs{
	align-items: flex-start;
}
.aife{
	align-items: flex-end;
}
.jcc{
	justify-content: center;
}
.jcfs{
	justify-content: flex-start !important;
}
.jcsb{
	justify-content: space-between;
}
.jcfe{
	justify-content: flex-end;
}
.jcse{
	justify-content: space-evenly;
}
.jcsa{
	justify-content: space-around;
}
.asfs{
	align-self: flex-start;
}
.asfe{
	align-self: flex-end;
}
.asc{
	align-self: center;
}
.fdrr{
	flex-direction: row-reverse;
}
.g{
	gap: 15px;
}
.g2{
	gap: 30px;
}
.g3{
	gap: 45px;
}
.g4{
	gap: 60px;
}
.g5{
	gap: 75px;
}
.g6{
	gap: 90px;
}
// gen gap
@for $i from 1 through 60 {
	.g0#{$i} {
	  gap: #{$i}px;
	}
 }
// ! MARGIN
// auto
.ma{
	margin: auto !important;
}
.m0a{
	margin: 0 auto !important;
}
// all
.m{
	margin: 15px !important;
}
.m2{
	margin: 30px !important;
}
.m3{
	margin: 45px !important;
}
.m4{
	margin: 60px !important;
}
// left
.ml{
	margin-left: 15px !important;
}
.ml2{
	margin-left: 30px !important;
}
.ml3{
	margin-left: 45px !important;
}
.ml4{
	margin-left: 60px !important;
}
.ml010{
	margin-left: 10px !important;
}
.mla{
	margin-left: auto !important;
}
// right
.mr{
	margin-right: 15px !important;
}
.mr2{
	margin-right: 30px !important;
}
.mr3{
	margin-right: 45px !important;
}
.mr4{
	margin-right: 60px !important;
}
.mr010{
	margin-right: 10px !important;
}
.mra{
	margin-right: auto !important;
}
// x axis
.mx{
	margin: 0 15px !important;
}
.mx2{
	margin: 0 30px !important;
}
.mx3{
	margin: 0 45px !important;
}
.mx4{
	margin: 0 60px !important;
}
// y axis
.my{
	margin: 15px 0 !important;
}
.my2{
	margin: 30px 0 !important;
}
.my3{
	margin: 45px 0 !important;
}
.my4{
	margin: 60px 0 !important;
}
// top
.mt{
	margin-top: 15px !important;
}
.mt2{
	margin-top: 30px !important;
}
.mt3{
	margin-top: 45px !important;
}
.mt4{
	margin-top: 60px !important;
}
.mta{
	margin-top: auto !important;
}
// bottom
.mb{
	margin-bottom: 15px !important;
}
.mb2{
	margin-bottom: 30px !important;
}
.mb3{
	margin-bottom: 45px !important;
}
.mb4{
	margin-bottom: 60px !important;
}
.mb40{
	margin-bottom: 40% !important;
}
// other
.mt0{
	margin-top: 0 !important;
}
.mt05{
	margin-top: 5px !important;
}
.mb05{
	margin-bottom: 5px !important;
}
// gen - margin-top
@for $i from 1 through 60 {
	.mt-#{$i} {
	  margin-top: -#{$i}px !important;
	}
 }
// ? MARGIN
// ! PADDING
// top
.pt{
	padding-top: 15px !important;
}
.pt2{
	padding-top: 30px !important;
}
.pt3{
	padding-top: 45px !important;
}
.pt4{
	padding-top: 60px !important;
}
// bottom
.pb{
	padding-bottom: 15px !important;
}
.pb2{
	padding-bottom: 30px !important;
}
.pb3{
	padding-bottom: 45px !important;
}
.pb4{
	padding-bottom: 60px !important;
}
// x axis
.px{
	padding: 0 15px !important;
}
.px2{
	padding: 0 30px !important;
}
.px3{
	padding: 0 45px !important;
}
.px4{
	padding: 0 60px !important;
}
.px05{
	padding: 0 5px !important;
}
.px0{
	padding-left: 0 !important;
	padding-right: 0 !important;
}
// y axis
.py{
	padding: 15px 0 !important;
}
.py2{
	padding: 30px 0 !important;
}
.py3{
	padding: 45px 0 !important;
}
.py4{
	padding: 60px 0 !important;
}
// all
.p{
	padding: 15px !important;
}
.p2{
	padding: 30px !important;
}
.p3{
	padding: 45px !important;
}
.p4{
	padding: 60px !important;
}
// left
.pl{
	padding-left: 15px !important;
}
.pl2{
	padding-left: 30px !important;
}
.pl3{
	padding-left: 45px !important;
}
.pl4{
	padding-left: 60px !important;
}
// right
.pr{
	padding-right: 15px !important;
}
.pr2{
	padding-right: 30px !important;
}
.pr3{
	padding-right: 45px !important;
}
.pr4{
	padding-right: 60px !important;
}
// null
.pt0{
	padding-top: 0px !important;
}
.pr0{
	padding-right: 0px !important;
}
.pb0{
	padding-bottom: 0px !important;
}
.pl0{
	padding-left: 0px !important;
}
.p0{
	padding: 0px !important;
}
// ! TITLES & text
.title{
	font-size: 27px; // 27px @1080p // TODO clamp(min,def,max)
	font-weight: 600;
	animation: fsiAnim 1s forwards;
}
.title2{
	font-size: 20px; // TODO clamp(min,def,max)
	font-weight: 600;
}
.title3{
	font-size: 16px; // TODO clamp(min,def,max)
	font-weight: 600;
}
.tac{
	text-align: center;
}
.tal{
	text-align: left;
}
.tar{
	text-align: right;
}
.fsi{
	font-style: italic;
}
.tdu{
	text-decoration: underline;
}
.tdn{
	text-decoration: none;
}
.fw100{
	font-weight: 100;
}
.fw200{
	font-weight: 200;
}
.fw300{
	font-weight: 300;
}
.fw400{
	font-weight: 400;
}
.fw500{
	font-weight: 500;
}
.fw600{
	font-weight: 600;
}
.fw700{
	font-weight: 700;
}
.ffFuggles{
	font-family: Fuggles;
}
// gen font-size 1-60 px
@for $i from 1 through 60 {
	.fz#{$i} {
	  font-size: #{$i}px !important;
	}
 }
.twb{
	text-wrap: balance;
}
.wbba{
	word-break: break-all;
}
// ! BACKGROUND & COLOR
.brand{
	animation: brandAnim 5s forwards;
}
.brandi{
	color: $brand !important;
}
@keyframes brandAnim {
	0% {
		color: $black;
	  }
	  100% {
		color: $brand;
	  }
}
.brandBg{
	background: $brand !important;
}
.brandBgLight{
	background: lighten($brand, 30%) !important;
}
.danger{
	color: $danger !important;
}
.dangerBg{
	background: lighten($danger, 25%) !important;
}
.dangerBg2{
	background: lighten($danger, 15%) !important;
}
.dangerBorder{
	border: 2px solid $danger !important;
	&:hover{
		outline: 1px solid $danger !important;
		color: $danger !important;
	}
}
.blackBorder{
	border: 2px solid $black !important;
}
.white{
	color: $white !important;
}
.whiteBg{
	background: $white !important;
}
.black{
	color: $black !important;
}
.blackBg{
	background: $black !important;
}
.gray{
	color: $gray !important;
}
.grayBg{
	background: $gray !important;
}
.googleBg{
	background: #4285F4 !important;
}
// ! BORDERS
.brL{ // border-radius LARGE
	border-radius: $borderRadiusLarge !important;
}
.btrr0{
	border-top-right-radius: 0 !important;
}
.bbrr0{
	border-bottom-right-radius: 0 !important;
}
.brS{ // border-radius SMALL
	border-radius: $borderRadiusSmall !important;
}
.inputBorderGray{
	border: 1px solid #b3b3b3;
}
.br50{
	border-radius: 50%;
}
// ! WIDTH & HEIGHT
.wS{ // width Small/Medium/Large
	max-width: $wS;
}
.wM{ // width Small/Medium/Large
	max-width: $wM;
}
.wL{ // width Small/Medium/Large
	max-width: $wL;
}
.cardsWidth{
	max-width: $cardsWidth;
}
.maw100{
	max-width: 100% !important;
}
.w100{
	width: 100% !important;
}
.w95{
	width: 95% !important;
}
.w90{
	width: 90% !important;
}
.w80{
	width: 80% !important;
}
.w70{
	width: 70% !important;
}
.w60{
	width: 60% !important;
}
.w50{
	width: 50% !important;
}
.wfc{
	width: fit-content;
}
.h100{
	height: 100%;
}
.h100i{
	height: 100% !important;
}
// gen min-width
@for $i from 0 through 900 {
	.miw#{$i} {
	  min-width: #{$i}px;
	}
 }
 // gen max-width
@for $i from 0 through 500 {
	.maw#{$i} {
	  max-width: #{$i}px;
	}
 }
// gen width
@for $i from 0 through 600 {
	.wid#{$i} {
	  width: #{$i}px;
	}
 }
// gen height
@for $i from 0 through 500 {
	.hei#{$i} {
	  height: #{$i}px;
	}
 }
 // gen min-height
@for $i from 0 through 500 {
	.mih#{$i} {
	  min-height: #{$i}px;
	}
 }
  // gen max-height
@for $i from 0 through 500 {
	.mah#{$i} {
	  max-height: #{$i}px;
	}
 }
 // gen svg height
@for $i from 0 through 100 {
	.svgh#{$i} {
		svg{
			height: #{$i}px !important;
		}
	}
}
// gen svg width
@for $i from 0 through 100 {
	.svgw#{$i} {
		svg{
			width: #{$i}px !important;
		}
	}
}
// ! POSITION & DISPLAY
.por{
	position: relative !important;
}
.poa{
	position: absolute !important;
}
.pof{
	position: fixed !important;
}
.db{
	display: block;
}
.dn{
	display: none !important;
}
.c{
	left: 50%;
	transform: translate(-50%);
}
// gen top
@for $i from 0 through 160 {
	.t#{$i} {
		top: #{$i}px;
	}
 }
// gen -top
@for $i from 0 through 60 {
	.t-#{$i} {
		top: -#{$i}px;
	}
 }
// gen right
@for $i from 0 through 160 {
	.r#{$i} {
	  right: #{$i}px;
	}
 }
 // gen - right
@for $i from 0 through 160 {
	.r-#{$i} {
	  right: -#{$i}px;
	}
 }
// gen bottom
@for $i from 0 through 60 {
	.b#{$i} {
		bottom: #{$i}px;
	}
 }
 // gen - bottom
@for $i from 0 through 260 {
	.b-#{$i} {
		bottom: -#{$i}px;
	}
 }
// gen left
@for $i from 0 through 160 {
	.l#{$i} {
	  left: #{$i}px;
	}
 }
// ! z-index
.zi-1{
	z-index: -1;
}
.zi0{
	z-index: 0;
}
.zi1{
	z-index: 1;
}
.zi2{
	z-index: 2;
}
.zi3{
	z-index: 3;
}
.zi4{
	z-index: 4;
}
.zi5{
	z-index: 5;
}
.zi6{
	z-index: 6;
}
.zi7{
	z-index: 7;
}
.zi8{
	z-index: 8;
}
.zi9{
	z-index: 9;
}
// ! top
// gen top 1-60 px
@for $i from 1 through 60 {
	.t#{$i} {
	  top: #{$i}px !important;
	}
 }
// ! MEDIA
.mobile{
	@media (min-width:$mobile) and (min-width: calc($mobile + 1px)) { // show only on mobile
		display: none !important;
	}
}
.desktop{
	@media (max-width:$mobile) { // show only on desktop
		display: none !important;
	}
}
.visibility930{
	@media (width < 930px) { 
		display: none !important;
	}
	
}
.mob{
	@media (width <= $mobile) {// for mobile only
		&_pl{
			padding-left: 15px;
		}
	}
	} 
// ! hiddenInput
// input is centered to (.por: position: relative)
// parrent MUST have .por className
// to show browser native "required" hint, if input value is ""/false/...
.hiddenInput{
	position: absolute;
	left:50%;
	top: 100%;
	transform: translate(-50%,-100%);
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
}
// ? hiddenInput
// ! OTHER
.op0{
	opacity: 0;
}
.op0i{
	opacity: 0 !important;
}
.op05{
	background: rgba($color: #000000, $alpha: 0.5);
}
.oh{
	overflow: hidden;
}
.toe{
	text-overflow: ellipsis;
}
.wsn{
	white-space: nowrap;
}
.br{
	border: 1px solid red !important;
}
.bb{
	border: 1px solid blue !important;
}
.bg{
	border: 1px solid gray !important;
}
.bd{
	border: 2px dashed $gray600;
}
.bn{
	border: none !important;
}
.oa{
	overflow: auto;
}
.pen{
	pointer-events: none;
}
.pea{
	pointer-events: all;
}
.bfb{
	backdrop-filter: blur(2px);
}
.ofContain{
	object-fit: contain !important;
}
.ofCover{
	object-fit: cover !important;
}