@import "../../vars.scss";

.prodFullUserFileUpload{
	$color: #bababa;
	border: 1px solid $color;
	width: 50% !important;
	border-radius: 4px;
	height: 52px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: darken($color, 30%);
	font-family: Roboto;
	word-break: break-all;
	overflow: hidden;
	* {
		cursor: cell !important; 
	}
	&:hover{
		transition: 0.1s;
		outline: 1px solid $black !important;
		font-weight: 700;
	}
}