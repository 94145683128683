@import "./vars.scss";
@import "./shortcuts.scss";
@import "./hovers.scss";
@import "./animations.scss";
@import "./theme.scss";
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;200;300;400;500;600;700&display=swap');

// ! GLOBAL

// TODO * {} NOT body
body {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	background: $appBg;
	font-family: "Montserrat", sans-serif;
}

html {
	scroll-behavior: smooth;
  }

* { // !!
	box-sizing: border-box;
}

a{
	color: $black; // TODO ???
	// text-decoration: none;
}

section{
	padding: 15px;
	border-radius: $borderRadiusLarge;
	// !!
	img {
		width: 100%;
	}
}

input:disabled{
	font-weight: 700;
}

b{
	font-weight: 600;
}

// MUI buttons style by default
.MuiButtonBase-root.MuiButton-root{
	opacity: 0;
	animation: opAnim 0.9s forwards;
	display: block;
	margin: 0 auto;
	border-radius: $borderRadiusLarge;
	transition: 0.3;
	// active
	&:active{
		transform: translateY(2px);
		box-shadow: none;
	}
	// contained
	&.MuiButton-contained{
		background: $brand;
		&:hover{
			background: darken($brand, 5%);
			font-weight: 700;
		}
	}
	// outlined
	&.MuiButton-outlined{
		color: $brand;
		border: 1px solid $brand;
		&:hover{
			transition: 0.1s;
			outline: 1px solid $brand;
			font-weight: 700;
		}
	}
	// disabled
	&:disabled{
		background: $gray;
		color: $white;
	}
}

.disabled{
	background: $gray !important;
	color: $white;
}

// ! MUI TextField(input) style by default
.MuiFormControl-root.MuiTextField-root{ // input
	width: 100%;
}
.MuiInputBase-root.MuiFilledInput-root{ // autocompelete inner input
	padding-top: 8px !important;
}
// ? MUI TextField(input) style by default

// ! MUI Input helperText
// don't be a part of input, so I can make borderBottom red, if no value provided: ELSE underlines helperText
.muiHelperTextAbsolute{
	.MuiFormHelperText-root{
		position: absolute;
		bottom: -25px;
	}
}

// ! scrollbar
body::-webkit-scrollbar {
	width: 10px;
}
// other scrollbars
::-webkit-scrollbar {
	width: 10px;
	height: 10px;
}
::-webkit-scrollbar-thumb {
	background: lighten($dark800, 10%);
	border-radius: 20px;
}
::-webkit-scrollbar-track {
	background: $white;
	border-radius: 20px;
}
// ? scrollbar