@import "../../vars.scss";

// !!
label{
	display: flex;
	justify-content: center;
	align-items: center;
	// !! height of the `addImage` and `preview`
	img{
		// width: 100%;
		max-height: 380px;
		margin: 15px 0;
		object-fit: contain;
	}
}

.uploadedImg{
	position: relative;
	margin-left: 15px;
	margin-top: 15px;
	border-radius: $borderRadiusLarge;
	border: solid 1px gray;
	height: 120px !important;
	width: 140px !important;
	overflow: hidden;
}