@import "../../vars.scss";

.category{
	overflow: hidden;
	border-radius: $borderRadiusLarge;
	-webkit-text-stroke: 1px $black;
	font-family: Roboto;
	// ! height
	max-height: 280px; // height 1
	height: 20vw;
	@media (width <= 1700px) {
		height: 15vw; // height 2
	}
	@media (width <= $mobile) {
		height: 150px; // height 3
	}
	// !!
	img{
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	// title in category
	.title{
		font-size: 1.7vw;
		@media (width <= 1200px) {
			font-size: 2vw;
		}
		@media (width <= $mobile) {
			font-size: 3.5vh;
			white-space: nowrap;
		}
		@media (width <= 500px) {
			font-size: 2.5vh;
		}
	}
}

// ! CarouselBanner container
.Carousel__Container-sc-hyhecw-0.fPimMH{
	width: 70%;
}