@import "../../vars.scss";

// CarouselBanner 1 img width
@media (width <= $mobile){
    .kLfeLb {
        padding-left: 0px;
        grid-template-columns: repeat(1,100%);
        grid-column-gap: calc(0% + 0px);
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
    }
}