@import "../../vars.scss";

// !! mui chip tag outlined
.MuiChip-root.MuiChip-outlined{
	border: 1px solid $brand;
	color: $brand;
	&:hover{
		transition: 0.1s;
		outline: 1px solid $brand;
		font-weight: 700;
	}
}
// !! mui chip tag filled
.MuiChip-root.MuiChip-filled{
	color: $white;
	background: $brand;
}

// ! scrollableArea
// tags are scrollable in Card
.scrollableArea {
	display: flex; 
	flex-wrap: nowrap;
	overflow: auto;
	min-height: 47px; // !! if no tags height=47
	// center 1 tag
	max-width: fit-content;
	margin: 0 auto;
	@media (width<=$mobile) {
	}
}

.hideScrollbar{
	// !! scrollableArea prevent show-scrollbar
	-ms-overflow-style: none;  /* Internet Explorer 10+ */
	scrollbar-width: none;  /* Firefox */
	&::-webkit-scrollbar{
		display: none;  /* Safari and Chrome */
	}
}
