@import "../../vars.scss";

.searchField{
	height: 30px;
	border: 2px solid $black; 
	border-radius: $borderRadiusLarge;
	width: $wS;
	padding: 0 5px;
	background: none;
	text-align: center;
	text-indent: -15px;
}