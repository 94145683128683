@import "../../vars.scss";

.card{
	user-select: none; // !! for better work Draggable in "list" cardDesign(1)
	position: relative;
	border-radius: $borderRadiusLarge;
	cursor: default !important;
	&__top{
		border-top-left-radius: 20px;
		border-top-right-radius: 20px;
		padding: 5px;
		// !! card top icons
		svg{
			height: 33px;
			width: 33px;
		}
	}
	// !! card img / not svg
	img:not([src*="svg"]){
		object-fit: cover;
		// !!! for good exp in card and in full prod img should be 3/2 ratio: eg: 1200/800px
		// cardDesign2 height is 220px
		min-width: 330px;
	}
	&__title,&__brand{
		padding: 0 15px;
		// number of lines to show
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		line-clamp: 2; 
		-webkit-box-orient: vertical;
	}
}

.cards{
	max-width: $cardsWidth; // !! big screen => justify-content: flex-start
	width: 100%;
	justify-content: flex-start;
	@media (max-width:1364px) { // !! small screen => justify-content: center
		justify-content: center;
	}
	@media (max-width:$mobile) {
		// flex-direction: column;
		// align-items: center;
	}
}

.postFull{
	max-width: 1100px;
	padding: 0 15px;
	background: $white;
	border-radius: $borderRadiusLarge;
	padding: 20px 15px;
	&__article{
		max-width: 500px;
	}
	@media (width <= $mobile) { // mobile
		gap: 15px;
	}
	&__left{ // part where prod img located
		width: 540px; // !! padding
		@media (width <= $tablet) { // tablet
			width: 100%;
		}
	}
	&__right{
		width: 440px;
	}
	&__left,&__right{
		align-self: flex-start;
	}
	// !!
	img{
		margin-top: 15px;
		width: 100%;
		border-radius: $borderRadiusLarge;
	}
	// !!
	a{
		color: $brand;
	}
}

.sort{
	border-radius: $borderRadiusLarge;
	background: transparent;
	font-size: 20px; // !!
	width: 330px;
	border: 2px solid;
	height: 30px;
	font-family: 'Montserrat';
	font-size: 16px;
	text-transform: uppercase;
	option{
		text-align: center;
	}
}

.postFilters{
	display: flex;
	flex-wrap: wrap;
	gap: 15px;
	justify-content: space-between;
	align-items: center;
	margin: 0 auto;
	margin-bottom: 15px;
	padding: 0 15px;
	padding-left: 0;
	width: 1100px;
	@media (max-width:$tablet){
		padding-left: 15px;
		width: 360px;
	}
}