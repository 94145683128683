@mixin transition {
	transition: 1s;
}

@mixin disabledMuiInput {
	.MuiInputBase-input.MuiInput-input.Mui-disabled,
	.MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled {
	  -webkit-text-fill-color: #848484;
	  font-weight: 600;
	}
	// helper text/label
	.MuiFormLabel-root,
	.MuiFormHelperText-root{
		-webkit-text-fill-color: #848484;
	}
  }

.dark{
	@include transition;
	@include disabledMuiInput;
	background: $dark100;
	color: $white;
	*:not(.tab_active,button,.star-svg,.filled-icons,path) {
		color: $white;
	}
	svg:not(.star-svg){
		fill: $white;
	}
	input,textarea{
		// background: $dark300 !important;
		color: $white !important;
		border-color: $white !important;
	}
	option,.cartReminder,input[type="search"]{
		background: $dark300 !important;
	}
	.cartReminder{
		box-shadow: 0px 10px 15px -3px rgba(182, 182, 182, 0.15);
	}
	.card,.cartCard,.CodeMirror,.subTotal, .postFull{
		background: $dark200;
	}
	.filter{
		border-color: $white;
	}
	section{
		background: $dark200;
	}
	.line,.round{
		background: $white;
		color: $black !important;
	}
	// !! cards' hovers
	.card, .cartCard, .orderCard{
		&:hover{
			box-shadow: 0px 10px 15px -3px rgba(182, 182, 182, 0.15);
		}
	}
	// skipHoverSvg
	.skipHoverSvg{
		&:hover{
			filter: contrast(100%);
			fill: $white !important;
		}
	}
}

// ! light
.light{
	@include transition;
	@include disabledMuiInput;
	.card,.cartCard,.CodeMirror,.subTotal,section,.cartReminder{
		background: $white;
	}
	.line,.round{
		background: $grayLight;
	}
	// skipHoverSvg
	.skipHoverSvg{
		&:hover{
			filter: contrast(100%);
			fill: $black !important;
		}
	}
}

// ! other
// mui dialog
.darkDialog{
	.MuiDialog-container.MuiDialog-scrollPaper{
		.MuiPaper-root{
			@include transition;
			background: $dark300 !important;
		}
	}
	// !!
	* {
		color: $white;
	}
	input{
		color: $white;
	}
}

// ! mui drawer (HeaderDrawer)
.MuiDrawer-root.MuiDrawer-modal.darkDrawer{
	.MuiPaper-root{
		@include transition;
		background: $dark300 !important;
	}
	// !!
	* {
		color: $white;
	}
}