@import "../../vars.scss";

.scrollTop{
	position: fixed;
	bottom: 15px;
	right: 15px;
	width: 50px !important;
	height: 50px !important;
	@media (width <= $mobile) { // mobile
		bottom: 55px;
		right: -8px;
	}
}

// ! LoadingBar
.loading-bar {
	width: 100%;
	height: 2px;
	background: #ccc;
	overflow: hidden;
  }
  
  .filler {
	height: 100%;
	background: $brand; /* Fill color */
	transition: width 0.1s;
  }
// ? LoadingBar