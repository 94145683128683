@import "../../vars.scss";

.cartCard{
	max-width: $wM;
	// !!
	@media (width <= $mobile){
		flex-wrap: wrap;
		padding-top: 30px;
		padding-bottom: 30px;
	}
	// !!
	img{
		border-radius: $borderRadiusMedium !important;
		@media (width > $mobile) {
			$height: 65px;
			height: $height;
			max-width: calc($height * (16/9));
			object-fit: cover;
		}
		@media (width <= $mobile) {
			width: 100% !important;
			height: auto;
		}
	}
	&_boxShadow{
		box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.15);
	}
}

.cartCardDelete{
	position: absolute;
	top: 5px;
	right: 10px;
	@media (width<=$mobile) {
		top: 0px;
	}
}

.hideSvg{
	svg{
		display: none;
	}
}

// ! CartSteps
.line{
	$width: 5.5%;
	height: 2px;
	&__small{
		width: $width;
	}
	&__big{
		width: $width * 2;
	}
}
.round{
	width: 40px;
	height: 40px;
	border-radius: 50%;
}

.cartStep{
	position: relative;
	&__text{
		position: absolute;
		top: 40px;
		width: 200px; // !! text goes to next line if much text: can look bad
		text-align: center;
	}
}

.cartReminder{
	position: fixed;
	z-index: 5;
	display: flex;
	align-items: center;
	padding: 15px;
	border-radius: $borderRadiusLarge;
	white-space: nowrap;
	box-shadow: 0px 10px 15px -3px rgba(182, 182, 182, 0.55);
	margin-right: 15px;
	// ! media
	@media (width <= calc($mobile + 360px)) { // mobile
		bottom: 60px;
		left: 50%;
		transform: translate(-50%);
	}
	@media (width > calc($mobile + 360px)) { // pc
		top: 75px;
		right: 0px;
	}
}

.cartShipmentCost{
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 45px;
	margin: 15px;
	margin-top: 0;
}

.normalShipmentIcon{
	@media (width>$mobile) {
		margin-left: 25px !important;
	}
}

// dialog cartCard (Product added to cart)
.hasDigitalDownload{
	position: relative;
	bottom: -6px;
	@media (width <= $mobile) {
		width: 40px; // brake words: 1 line = 1 word
		bottom: -20px;
	}
}