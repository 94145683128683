// ! delAnim
.delAnim{
	animation: delAnim 0.9s forwards;
}
@keyframes delAnim {
	to{
		opacity: 0;
		scale: 0;
	}
}
// ! cardAnim
.cardAnim{
	opacity: 0;
	scale: 0.9;
	animation: cardAnim 0.9s forwards;
}
@keyframes cardAnim {
	to{
		opacity: 1;
		scale: 1;
	}
}
// ! opAnim
.opAnim{
	opacity: 0;
	animation: opAnim 0.9s forwards;
}
@keyframes opAnim {
	to{
		opacity: 1;
	}
}
// ! blurAnim
.blurAnim{
	filter: blur(5px);
	animation: blurAnim 0.5s forwards;
}
@keyframes blurAnim {
	to{
		filter: blur(0px);
	}
}
// ! fwAnim
.fwAnim{
	animation: fwAnim 0.7s forwards;
}
@keyframes fwAnim {
	0%{
		font-weight: 100;
	}
	to{
		font-style: 600;
	}
}
// ! fsiAnim
.fsiAnim{
	animation: fsiAnim 6.7s forwards;
}
@keyframes fsiAnim {
	0%{
		font-style: italic;
	}
	to{
		font-style: normal;
	}
}