// color
$black: #131313;
$white: #fff;
$brand: #673BD9;
$appBg: #F2F2F2;
$grayLight: #E3E3E3;
$gray: #777777;
$gray600: #575757;
$danger: tomato;
$dark800: #222;
$dark700: #333;
$dark100: #111;
$dark200: #1E1E1E;
$dark300: #2D2D2D;
// width
$wS: 330px; // 360p mobile -30px padding
$wM: 675px;
$wL: 1100px;
$cardsWidth: 1380px;
// breakpoints
$mobile: 768px;
$tablet: 1100px;
// borders
$borderRadiusLarge: 20px;
$borderRadiusMedium: 10px;
$borderRadiusSmall: 5px;
// other
$fontMain: Montserrat;
$fontSecondary: Roboto;
// boxShadow
$boxShadowLight: 0px 10px 15px -3px rgba(0,0,0,0.08);
$boxShadowHard: 0px 10px 15px -3px rgba(0,0,0,0.16);