@import "../../vars.scss";

$svgSize: 35;

@mixin headerValues {
	z-index: 9; // to be under MUI drawer (.headerDrawer)
	width: 100%;
	display: flex;
	align-items: center;
	background: $dark800;
	height: 60px;
}

// !!
header{
	@include headerValues;
	// !! header icons
	svg{
	height: $svgSize+px !important;
	width: $svgSize+px !important;
	color: $white;
}
}

.headerDrawer{
	width: 360px;
	padding: 10px !important;
	@media (max-width:$mobile) {
		width: 100vw;
		overflow-x: hidden;
	}
}

.headerIcons{
	display: flex;
	@media (max-width:$mobile) {
		@include headerValues;
		bottom: 0;
		left: 0;
		justify-content: space-evenly;
	}
}

// ! headerIcon
.headerIcon{
	color: $white !important;
		svg{
		fill: $white !important;
	}
	&.visited{
		color: $brand !important;
		svg{
			fill: $brand !important;
		}
	}
}

// ! MUI SvgIcon (headerIcon)
.css-i4bv87-MuiSvgIcon-root{
	@media (width<=$mobile) {
		font-size: 2rem !important;
	}
}
// ? headerIcon

.iconCount{
	position: absolute;
	top: 7px;
	background: $brand; 
	border-radius: 50%;
	width: 16px;
	height: 16px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 12px;
	color: $white;
	pointer-events: none;
	&_wide{
		width: auto;
		padding: 0 2px;
		border-radius: 20px;
	}
	@media (width<=$mobile) {
		top: 13px;
	}
}

.logoMain{
	height: 40px;
}

.themeSwitch{
	align-self: center;
	position: relative;
	top: 2px;
	// ! adjust mobile themeSwitch text
	@media (width<=$mobile) {
		+ div{
			position: relative;
			top: -1px;
		}
	}
	// !!
	svg{
		height: calc($svgSize - 4)+px !important;
	}
}

// ! Burger
#burger__toggle {
	opacity: 0;
	width: 0;
 }
 #burger__toggle:checked + .burger__btn > span {
	transform: rotate(45deg);
 }
 #burger__toggle:checked + .burger__btn > span::before {
	top: 0;
	transform: rotate(0deg);
 }
 #burger__toggle:checked + .burger__btn > span::after {
	top: 0;
	transform: rotate(90deg);
 }
 #burger__toggle:checked ~ .burger__box {
	left: 0 !important;
 }
 .burger__btn {
	 position: fixed;
	 top: 8px;
	 right: 20px;
	 padding:20px 0;
	width: 40px;
	height: 40px;
	cursor: pointer;
	z-index: 9;
 }
 .burger__btn > span,
 .burger__btn > span::before,
 .burger__btn > span::after {
	display: block;
	position: absolute;
	width: 100%;
	height: 3px;
	background: $white;
	transition-duration: 0.25s;
 }
 .burger__btn > span::before {
	content: "";
	top: -8px;
 }
 .burger__btn > span::after {
	content: "";
	top: 8px;
 }
 
// ? Burger