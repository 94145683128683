@import "../../vars.scss";

@mixin sliderImgSizeMixin() {
	width: 541px;
	height: 541px;
	@media (width<=$mobile) {
		height: 400px;
		object-fit: contain;
		width: 100%;
	}
} 

// ! CarouselProduct
.react-slider img{ // hide fullProd slider all imgs border
	border: none;
}

.react-slider__description{ // hide little _ under imgs
	display: none;
}

.react-slider{
	height: 100%;
	.react-slider__picture{
		img{
			@include sliderImgSizeMixin();
			object-fit: contain;
			width: 100%;
		}
	}
	.react-slider__ul{ // hint imgs
		min-height: 70px;
		display: flex;
		margin-top: 0;
		li{ // one hint img wrap
			display: flex;
			gap: 5px;
			align-items: center;
		}
		li.active img{ // prevent jumping hint images on mobile
			position: relative;
		}
		img{ // one hint img
			border-radius: 0;
			object-fit: contain;
			height: 55px;
			object-fit: cover;
		}
		// media: > mobile
		@media (width > $mobile) {			
			img{ // one hint img
				min-width: 80px;
			}
		}
	}
	&__imgZoom{
		position: absolute !important;
		z-index: 99999999999999 !important;
		pointer-events: none;
		top: 0 !important;
		left: -19.5px;
		@include sliderImgSizeMixin();
	}
	&__lens{
		@media (width <= $tablet) {
			display: none !important;
		}
	}
}

.smallImgPreviewWrap{ // .react-slider__ul / hint imgs in edit mode
	gap: 25px;
	padding-left: 15px;
	border: 2px dashed $gray600;
	border-radius: 20px;
	min-height: 100px !important;
	img{ // one hint img in edit mode
		height: 55px !important;
		min-width: 80px !important;
		border: none !important;
		object-fit: fill !important;
	}
}
// ? CarouselProduct

// ! CarouselBanner
// center image(s) if not 3
.Carousel__ItemSet-sc-hyhecw-3.daGMts{
	@media (width >= 768px) {
		display: flex; 
		justify-content: center;
	}
}
// ? CarouselBanner